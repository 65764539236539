<template>
<div class="favorites">
  <div class="content" v-if="maxVersion">
    <h2>版本管理</h2>
    <lh-button @click="clearFile" icon="lh-icon-delete" backgroundColor="red">清除服务器失效文件</lh-button>
    <lh-dialog :visible.sync="visible">
      <div slot="title">添加版本</div>
      <table>
        <tr>
          <td>上传安装包</td>
          <td>
            <lh-button accept=".apk" @change="selectFile">
              选择apk文件
            </lh-button>
            <div v-if="apkFile" style="font-size: 10px;color: red;">
              {{ apkFile.name }}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            设置版本号
            <div v-if="maxVersion" style="font-size: 10px;color: red;">
              *当前最大版本：{{maxVersion.versionString}}
            </div>
          </td>
          <td>
            <lh-select v-model="version.versionString">
              <lh-option v-for="(item,index) of vList" :value="item" :key="index">
                {{ item }}
              </lh-option>
            </lh-select>
          </td>
        </tr>
        <tr>
          <td>是否强制更新</td>
          <td>
            <lh-radio v-model="version.force" :radioValue="true">是</lh-radio>
            <lh-radio v-model="version.force" :radioValue="false">否</lh-radio>
          </td>
        </tr>
      </table>
      <div slot="footer">
        <lh-button @click="confirm" :loading="loading">确认</lh-button>
      </div>
    </lh-dialog>
    <lh-dialog :visible.sync="eVisible">
      <div>
        <span>是否强制更新：</span>
        <lh-radio v-model="eVersion.force" :radioValue="true">是</lh-radio>
        <lh-radio v-model="eVersion.force" :radioValue="false">否</lh-radio>
      </div>
      <lh-checkbox v-model="eVersion.update">设置为最新版本</lh-checkbox>
      <div slot="footer">
        <lh-button @click="save" :loading="loading">保存</lh-button>
      </div>
    </lh-dialog>
    <lh-button v-if="maxVersion" @click="visible=true">添加新版本</lh-button>
    <lh-table>
      <lh-tr>
        <lh-th>序号</lh-th>
        <lh-th>版本号</lh-th>
        <lh-th>更新时间</lh-th>
        <lh-th>是否强制更新</lh-th>
        <lh-th>操作</lh-th>
      </lh-tr>
      <lh-tr v-for="(item,index) of versionList" :key="item.id">
        <lh-td>{{ index+1 }}</lh-td>
        <lh-td>{{ item.versionString }}</lh-td>
        <lh-td>
          {{ item.updateTime }}
          <div v-if="index==0" style="color:red;font-size: 10px;">*最新版本</div>
        </lh-td>
        <lh-td>
          {{ item.force?"是":"否" }}
        </lh-td>
        <lh-td>
          <!-- <lh-button @click="updateVersion" :disabled="index==0">
            {{ index==0?'最新版本':"设置为最新版本" }}
          </lh-button> -->
          <lh-button @click="edit(index)">编辑</lh-button>
          <lh-button @click="deleteVersion(index)" icon="lh-icon-delete" backgroundColor="red">
            删除
          </lh-button>
        </lh-td>
      </lh-tr>
    </lh-table>
  </div>
</div>
</template>

<script>
import request from "../../utils/request";
import {uploadFile,SaveUrl} from "../../utils/file";
import { formatDate } from "../../utils/lhUtils";
export default {
  name:"Favorites",
  data(){
    return {
      visible:false,
      maxVersion:null,
      versionList:null,
      vList:null,
      loading:false,
      version:{
        versionString:"1.0.0",
        fileId:null,
        force:false
      },
      eVisible:false,
      eVersion:{
        id:null,
        force:false,
        update:false,
        updateTime:null
      },
      apkFile:null
    }
  },
  mounted(){
    this.getMaxVersion();
    this.getVersionList();
  },
  methods:{
    getMaxVersion:function(){
      let that=this;
      request.get("/version/getMaxVersion").then((data)=>{
        that.maxVersion=data;
        let vList=[];
        vList.push((data.partMap.p1+1)+"."+data.partMap.p2+"."+data.partMap.p3);
        vList.push(data.partMap.p1+"."+(data.partMap.p2+1)+"."+data.partMap.p3);
        vList.push(data.partMap.p1+"."+data.partMap.p2+"."+(data.partMap.p3+1));
        that.vList=vList;
        that.version.versionString=vList[0];
      });
    },
    selectFile:function(e){
      console.log("e",e);
      this.apkFile=e.target.files[0];
    },
    confirm:function(){
      if(this.apkFile==null){
        this.$lhMessage("warn","请上传安装包");
        return;
      }
      let that=this;
      this.loading=true;
      uploadFile(this.apkFile,SaveUrl.APK,"apk").then((data)=>{
        that.version.fileId=data.id;
        request.post("/version/addVersion",that.version).then((version)=>{
          that.maxVersion=version;
          that.visible=false;
          that.$lhMessage("success","上传成功");
          that.getVersionList();
        }).finally(()=>{
          that.loading=false;
        });
      }).finally(()=>{
        that.loading=false;
      })
    },
    clearFile:function(){
      this.$lhLoading.open();
      let that=this;
      request.post("/file/deleteLostFile").then(()=>{
        that.$lhMessage("success","清除成功");
      }).finally(()=>{
        that.$lhLoading.close();
      })
    },
    getVersionList:function(){
      this.$lhLoading.open();
      let that=this;
      request.get("/version/getVersionList").then((data)=>{
        that.versionList=data;
      }).finally(()=>{
        that.$lhLoading.close()
      })
    },
    updateVersion:function(id){
      this.$lhLoading.open();
      let that=this;
      request.post("/version/updateVersionById",{id:id}).then(()=>{
        that.getVersionList();
      }).finally(()=>{
        that.$lhLoading.close();
      })
    },
    deleteVersion:function(index){
      let that=this;
      this.$lhMessageBox("删除版本","确认删除版本"+this.versionList[index].versionString+"？").then(()=>{
        that.$lhLoading.open();
        request.post("/version/deleteVersionById",{id:that.versionList[index].id},{
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(()=>{
          that.versionList.splice(index,1);
          that.$lhMessage("success","删除成功");
        }).finally(()=>{
          that.$lhLoading.close();
        });
			});
    },
    edit:function(index){
      this.eVersion.id=this.versionList[index].id;
      this.eVersion.force=this.versionList[index].force;
      this.eVisible=true;
    },
    save:function(){
      this.loading=true;
      let that=this;
      if(this.eVersion.update){
        this.eVersion.updateTime=formatDate(new Date());
      }else{
        this.eVersion.updateTime=null;
      }
      request.post("/version/updateVersion",this.eVersion).then(()=>{
        that.getVersionList();
        that.eVisible=false;
        that.$lhMessage("success","保存成功");
      }).finally(()=>{
        that.loading=false;
      });
    }
    
  }
}
</script>

<style scoped lang="less">
.favorites{
  display: flex;
  justify-content: center;
}
</style>