import axios from "./request";

export const SaveUrl={
  DEFAULT:"DEFAULT",
  APK:"APK"
}

export const uploadFile=function(file,saveUrl,remarks){
  let p=new Promise((resolve,reject)=>{
    if(process.env.NODE_ENV=="development"){
      uploadFile1(file,saveUrl,remarks).then((data)=>{
        resolve(data);
      }).catch((err)=>{
        reject(err);
      })
    }else{
      uploadFile2(file,saveUrl,remarks).then((data)=>{
        resolve(data);
      }).catch((err)=>{
        reject(err);
      })
    }
  });
  return p;
}

/**
 * 上传文件，直接向后端上传文件，再由后端上传到对象存储，后端部署在微信云托管，由于微信云托管集群的nginx的限制，不能上传大文件
 * @param {*} file 
 * @param {*} saveUrl 
 * @param {*} remarks 
 * @returns 
 */
const uploadFile1=function(file,saveUrl,remarks){
  let p=new Promise((resolve,reject)=>{
    let formData=new FormData();
    formData.append("file",file);
    formData.append("saveUrl",saveUrl);
    formData.append("remarks",remarks);
    axios.post("/file/uploadFile",formData).then((res)=>{
      resolve(res);
    }).catch((err)=>{
      reject(err);
    });
  });
  return p;
}

/**
 * 上传文件，向微信云托管的对象存储上传文件，再把文件信息上传到后端
 * @param {*} file 
 * @param {*} saveUrl 
 * @param {*} remarks 
 */
const uploadFile2=function(file,saveUrl,remarks){
  let suffix=file.name.split(".").pop();
  suffix="."+suffix;
  let p=new Promise((resolve,reject)=>{
    /** 获取上传链接 */
    axios.get("/file/getFileUploadUrl",{
      params:{
        suffix:suffix,
        saveUrl:saveUrl
      }
    }).then((data)=>{
      //console.log("uploadData",data);
      let form=new FormData();
      form.append("key",data.key);
      form.append("Signature",data.authorization);
      form.append("x-cos-security-token",data.token);
      form.append("x-cos-meta-fileid",data.cos_file_id);
      form.append("file",file);
      /** 向云托管的对象存储上传文件 */
      axios.post(data.url,form).then(()=>{
        let fileInfo={
          key:data.key,
          file_id:data.file_id,
          remarks:remarks
        }
        /** 将文件信息上传到后端 */
        axios.post("/file/addFile",fileInfo).then((filePojo)=>{
          resolve(filePojo);
        }).catch((err)=>{
          reject(err);
        });
      }).catch((err)=>{
        reject(err);
      });
    }).catch((err)=>{
      reject(err);
    })
  })
  return p;
}